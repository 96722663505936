import React from "react"
import SEO from "../components/seo"


const ProjetPage = () => (
  <div  id="app_center" class="container_center">
    <SEO title="Contactez Nous" description="Pour nous confier vos projets c'est pas ici."/>

    <h1>Un projet à réaliser ?</h1>

    <div class="container_line">Nous serions ravis de vous rencontrer afin de parler de vos idées et projets.</div>

    <div class="container_line">
    N'hésitez pas à nous contacter pour plus de détails.
    </div>

  </div>
)

export default ProjetPage
